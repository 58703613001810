<template>
  <div>
    <div class="d-flex justify-content-end">
      <feather-icon
        v-if="organizationRequiresEditConfirmation"
        v-b-tooltip.noninteractive.hover.bottom
        :title="$t('Edit')"
        :icon="isEditable ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
        size="36"
        class="cursor-pointer"
        @click="isEditAble()"
      />
    </div>
    <error-alert
      :fields="fields"
      :error="error"
    />
    <b-form>
      <validation-observer
        ref="skuCreateVal"
      >
        <label class="font-weight-bolder font-medium-5"> {{ $t('SKU Creation') }} </label>
        <div class="d-flex mb-1">
          <component
            :is="fields[field].type"
            v-for="field in ['type']"
            :key="field"
            :ref="`${field}_ref`"
            v-model="skuForm[field]"
            v-bind="getProps(field)"
            :name="field"
            :is-editable="isEditable"
          />
        </div>
        <b-row>
          <b-col md="6">
            <component
              :is="fields[field].type"
              v-for="field in ['starting_number']"
              :key="field"
              :ref="`${field}_ref`"
              v-model="skuForm[field]"
              v-bind="getProps(field)"
              :name="field"
              :is-editable="isEditable"
            />
          </b-col>
          <b-col md="6">
            <component
              :is="fields[field].type"
              v-for="field in ['prefix', 'suffix']"
              :key="field"
              :ref="`${field}_ref`"
              v-model="skuForm[field]"
              v-bind="getProps(field)"
              :name="field"
              :is-editable="isEditable"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <label class="font-weight-bolder font-medium-5"> {{ $t('Barcode') }}</label>
            <component
              :is="fields[field].type"
              v-for="field in ['barcode']"
              :key="field"
              :ref="`${field}_ref`"
              v-model="skuForm[field]"
              v-bind="getProps(field)"
              :name="field"
              :is-editable="isEditable"
            />
          </b-col>
        </b-row>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="!isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="$router.push({ name: 'settings' })"
              >
                {{ $t('Back to Setting') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="cancel"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
            </div>
            <div class="d-flex">
              <b-button
                class="mr-1 d-flex"
                @click="testBarcode"
              >
                <feather-icon
                  class="mr-1"
                  icon="LPrintIcon"
                  size="16"
                />
                <!--                <Printer>-->
                <!--                  <div-->
                <!--                    id="testBarcode"-->
                <!--                    hidden-->
                <!--                  >-->
                <!--                    <div v-html="testBarcodeTemplate" />-->
                <!--                  </div>-->
                <!--                </Printer>-->
                <span>{{ $t('Test Barcode') }}</span>
              </b-button>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-modal
      id="customer-list-file-upload_download"
      centered
      hide-header
      body-class="customer-list-file-upload_download-modal-body"
      footer-class="customer-list-file-upload_download-modal-footer"
      ok-variant="success"
      ok-title="Hide"
      ok-only
      modal-class="customer-list-file-upload_download"
      @hidden="resetModal"
    >
      <!--      :ok-disabled="!chosenFiles"-->
      <!--      @ok="uploadFile"-->
      <div class="text-center font-weight-bolder pb-2">
        {{ $t('Test Barcode') }}
      </div>
      <div class="d-flex justify-content-center mb-2" />
      <div
        class="mb-2 border-dashed p-5 w-50 mx-auto d-flex justify-content-center position-relative"
        style="height: 300px"
      >
        <div v-html="testBarcodeTemplate" />
        <b-overlay
          :show="isTestBarcodeTemplateLoading"
          class="position-absolute"
          style="width: 100%; height: 100%; top: 0; left: 0;"
          :style="{zIndex: isTestBarcodeTemplateLoading ? '100': '-1',}"
          rounded="sm"
        />
      </div>

    </b-modal>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>
<script>
import { required } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BCol, BForm, BRow, BModal, BOverlay, VBTooltip,
} from 'bootstrap-vue'
import { scrollToError } from '@core/utils/utils'
import { mapState } from 'vuex'
import Printer from '@/views/components/Printer/Printer.vue'
import store from '@/store'
import config from '../skuConfig'

export default {
  name: 'SkuForm',
  components: {
    ErrorAlert,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    Printer,
    BModal,
    BOverlay,
    BButton,
  },
  data() {
    return {
      error: {},
      required,
      testBarcodeTemplate: null,
      isTestBarcodeTemplateLoading: false,
      onSubmit: false,
      isEditable: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    ...mapState('sku', ['skuForm', 'skuFormClone']),
    isFormChanged() {
      return JSON.stringify(this.skuForm) !== JSON.stringify(this.skuFormClone)
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    isEditAble() {
      return this.isEditable = !this.isEditable
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    submit() {
      this.error = {}
      this.onSubmit = true

      this.$refs.skuCreateVal.validate()
        .then(success => {
          if (success) {
            const data = this.mappingFields(Object.keys(this.fields), this.skuForm)
            this.sendNotification(this, data, `${this.MODULE_NAME}/update`).then(() => {
              this.clear()
            }).catch(err => {
              this.error = err.response?.data
            }).finally(() => {
              this.onSubmit = false
            })
          } else {
            scrollToError(this, this.$refs.skuCreateVal)
          }
        })
    },
    clear() {
      this.$refs.skuCreateVal.reset()
      this.$emit('fetchData')
    },
    cancel() {
      this.$refs.skuCreateVal.reset()
      const smth = this.skuFormClone
      this.$store.commit(`${this.MODULE_NAME}/SET_SKU_FORM`, smth)
      // this.clear()
    },
    showModalToChoseCategory() {
      this.$bvModal.show('customer-list-file-upload_download')
    },
    hideModalToChoseCategory() {
      this.$bvModal.hide('customer-list-file-upload_download')
    },
    getProps(fieldName) {
      return {
        is: this.fields[fieldName].type,
        field: this.fields[fieldName],
        name: fieldName,
      }
    },
    resetModal() {
      this.testBarcodeTemplate = null
    },
    testBarcode() {
      this.error = {}
      this.showModalToChoseCategory()
      this.isTestBarcodeTemplateLoading = true

      this.$refs.skuCreateVal.validate()
        .then(success => {
          if (success) {
            const data = this.skuForm

            this.$store.dispatch(`${this.MODULE_NAME}/testBarcode`, data).then(res => {
              const { html } = res.data.data

              this.testBarcodeTemplate = html
            }).catch(err => {
              console.log(err)
              this.isTestBarcodeTemplateLoading = true
            }).finally(() => {
              this.isTestBarcodeTemplateLoading = false
            })
          }
        })
    },
  },
  setup() {
    const MODULE_NAME = 'sku'
    const { fields } = config()
    return {
      MODULE_NAME,
      fields,
    }
  },
}
</script>
