<template>
  <div>
    <label class="font-weight-bolder font-medium-5 mt-2"> {{ $t('SKU Creation') }} </label>
    <b-row>
      <b-col md="12">
        <div
            v-for="field in ['type']"
            :key="field"
        >
          <span
              class="font-weight-bolder d-block"
              style="margin-bottom: 5px; margin-left: 10px"
          >{{ fields[field].label }}</span>
          <b-skeleton
              width="350px"
              height="25px"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <div
            v-for="field in ['starting_number']"
            :key="field"
        >
          <span
              class="font-weight-bolder d-block"
              style="margin-bottom: 5px; margin-left: 10px"
          >{{ fields[field].label }}</span>
          <b-skeleton
              height="35px"
              style="border-radius: 5px"
          />
        </div>
      </b-col>
      <b-col md="6">
        <div
            v-for="field in ['prefix', 'suffix']"
            :key="field"
        >
          <span
              class="font-weight-bolder d-block"
              style="margin-bottom: 5px; margin-left: 10px"
          >{{ fields[field].label }}</span>
          <b-skeleton
              height="35px"
              style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
    <label class="font-weight-bolder font-medium-5 mt-2"> {{ $t('Barcode') }} </label>
    <b-row>
      <b-col md="12">
        <div
            v-for="field in ['barcode']"
            :key="field"
        >
          <span
              class="font-weight-bolder d-block"
              style="margin-bottom: 5px; margin-left: 10px"
          >{{ fields[field].label }}</span>
          <b-skeleton
              height="100px"
              style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BSkeleton, BCol,
} from 'bootstrap-vue'
import config from '../skuConfig'

export default {
  name: 'Skeleton',
  components: {
    BCol,
    BRow,
    BSkeleton,
  },
  setup() {
    const { fields } = config()
    return {
      fields,
    }
  },
}
</script>
