import axios from '@/libs/axios'

const endpoint = () => 'settings/sku-creation'

const get = (ctx, queryParams) => axios.get(`/${endpoint()}`, { params: queryParams })

const update = (ctx, data) => axios.post(`/${endpoint()}`, data)

const testBarcode = (ctx, data) => axios.post(`/${endpoint()}-test`, data)

export default {
  get,
  update,
  testBarcode,
}
