import {
  TEXTAREA_INPUT,
  TEXT_INPUT, RADIO_GROUP,
} from '@/views/components/DynamicForm/constants'

export default function config() {
  const fields = {
    type: {
      type: RADIO_GROUP,
      rules: 'required',
      list: [
        { value: 'autogenerated', text: 'Autogenerated' },
        { value: 'manually_assigned', text: 'Manually assigned' },
      ],
    },
    starting_number: {
      type: TEXT_INPUT,
      label: 'Starting number',
      rules: 'required|numeric',
      placeholder: 'Type...',
    },
    prefix: {
      type: TEXT_INPUT,
      label: 'Prefix',
      placeholder: 'Type...',
    },
    suffix: {
      type: TEXT_INPUT,
      label: 'Suffix',
      placeholder: 'Type...',
    },
    rate: {
      type: TEXT_INPUT,
      label: 'Rate',
      rules: 'required|numeric',
      placeholder: 'Type...',
    },
    barcode: {
      type: TEXTAREA_INPUT,
      label: 'Template',
      rules: 'required',
      options: {
        rows: 8,
      },
      placeholder: ' ',
    },
  }
  return {
    fields,
  }
}
