<template>
  <div v-if="!loading">
    <sku-form
      ref="sku-form"
      @fetchData="fetchData"
    />
  </div>
  <div v-else>
    <skeleton />
  </div>
</template>
<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import skuModule from '@/store/settings/catalog/skus'
import Skeleton from './components/Skeleton.vue'
import SkuForm from './components/SkuForm.vue'
import config from './skuConfig'

export default {
  name: 'RatesCreate',
  computed: {
    ...mapState('listModule', { priceTiers: 'priceTierList' }),
    ...mapState('sku', ['rates']),
    ...mapGetters('rates', ['getRateByTierId']),
  },
  created() {
    this.$emit('updateMenu', 'settings-catalog-skus')
    this.fetchData()
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['sku-form']) this.$refs['sku-form'].showConfirmationModal(next)
  },
  methods: {
    ...mapActions('sku', ['get']),
    ...mapMutations('sku', ['SET_SKU_FORM']),
    ...mapMutations('sku', ['SET_SKU_FORM_CLONE']),
    fetchData() {
      this.loading = true
      this.get()
        .then(res => {
          const { data } = res.data
          this.SET_SKU_FORM(data)
          this.SET_SKU_FORM_CLONE(data)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  // eslint-disable-next-line vue/order-in-components
  components: {
    Skeleton,
    SkuForm,
  },
  setup() {
    const MODULE_NAME = 'sku'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, skuModule)

    const { fields } = config()
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    const loading = ref(true)
    return {
      MODULE_NAME,
      loading,
      fields,
    }
  },
}
</script>
